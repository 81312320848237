import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { toPercent2, toPercent4 } from "../utils/display";
import moment from "moment";
// import {  } from "../utils/display";
export const width = '300px';
const Price = ({ symbol }) => {
  const [price, setPrice] = useState('')
  useEffect(() => {
    fetch(`/api/price/${symbol}`)
          .then((x) => x.json())
          .then(setPrice);
  }, [])
  
  return <div style={{width}}>
    <span>{symbol}价格(美元): </span>
    <span>{price}</span>
  </div>
}

const Computation = ({ title, name, displayFn }) => {
  const [data, setData] = useState('')
  useEffect(() => {
    fetch(`/api/computation/${name}`)
          .then((x) => x.json())
          .then(setData);
  }, [])

  const defaultFn = x => new Decimal(x).toFixed(2)
  
  return <div style={{width}}>
    <span>{title}: </span>
    <span> {data && (displayFn || defaultFn)(data) }</span>
  </div>
}

const FundingRate = ({ symbol }) => {
  const [data, setData] = useState('')
  useEffect(() => {
    fetch(`/api/lastFundingRate/${symbol}`)
          .then((x) => x.json())
          .then(setData);
  }, [])
  
  return <div style={{width}}>
    <span>{symbol} FR: </span>
    <span> {data && toPercent4(data)}</span>
  </div>
}
const GateFundingRate = ({ symbol }) => {
  const [data, setData] = useState('')
  useEffect(() => {
    fetch(`/api/gateLastFundingRate/${symbol}`)
          .then((x) => x.json())
          .then(setData);
  }, [])
  
  return <div style={{width}}>
    <span>{symbol} FR: </span>
    <span> {data && toPercent4(data)}</span>
  </div>
}

const TaskProcessRunning = ({ taskName }) => {
  const [running, setRunning] = useState(undefined)
  useEffect(() => {
    fetch(`/api/taskProcessRunning/${taskName}`)
          .then((x) => x.json())
          .then(setRunning);
  }, [])
  
  return <div style={{width}}>
    <span> Task: {taskName} </span>
    {running === undefined && <span>-</span>}
    {running === false && <span style={{color: 'red'}}>NOT RUNNING</span> }
    {running === true && <span>RUNNING</span> }
  </div>
}

const links = [
  ["Old Page", "/old"],
  ["Hedging", "/api/hedging"],
  ["Trade Fee", "/api/trade-fee/ADAUSDT"],
  // ["Coin Future Interests", "/api/binanceCoinFutureInterests"],
  ["USDT Future Interests", "/api/usdt-rates/90"],
  ["Hedge Fund Distribution", "/api/hedgeFundDistribution"],
  ["Auto HedgeInfo", "/api/autoHedgeInfo"],
  ["LIP Prices", "/api/liqPrices"],
  ["Hedge Balancing Info", "/api/hedgeBalancingInfo?cny=true"],
  ["Others Portfolio", "/api/computation/othersPortfolio"],
  ["Collateral Calculation", "/api/computation/collateralCalculation?borrowAmount=1100&borrowToken=NEAR&collateralToken=ETH&ratio=0.65"],
  ["All Stable", "/api/allStable"],
  ["Kraken Euro", "/api/krakenEuroOverPrice"],
  ["GateContract Summary", "/api/gateContractSummary"],
]

const symbols = [
  // "BTC",
  // "ADA",
  // "ERG",
  // "ETH",
  // "ALGO",
  // "XVS",
  // "BNB",
  // "COMP",
  // "BCH",
  // "DOT",
  // "EOS",
  // "EGLD",
]
const computations = [
  // ['币安OTC买入', 'cnyBuy'],
  // ['币安OTC买出', 'cnySell'],
  // ['Venus安全值', 'venusRatio'],
  // ['Venus Log', 'venusLog', x => {
  //   const [ts, can] = x.split(' ')
  //   return moment(Number(ts)).format('MM-DD HH:mm') + ' ' + can
  // }],
  // ['总资产', 'portfolioSum'],
  // ['总稳定币', 'stableSum'],
]

const fundingRates = [
  // 'DOT',
  // 'ADA',
  // 'BNB',
  // 'ETH',
  'NEARUSD_PERP',
  'ADAUSD_PERP',
  'AVAXUSD_PERP',
  'DOTUSD_PERP',
  'SUIUSD_PERP',
  'BNBUSD_PERP',
  'MINAUSDT',
  'LRCUSDT',
]
const gateFundingRates = [
  // 'DOT',
  // 'ADA',
  // 'BNB',
  // 'ETH',
  'DOG',
  'MAGA',
  'FB',
  'RWA',
  'TRUMP',
]

const taskProcessRunning = [
  'checkNotification'
]

const View = ({}) => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }}>
      {links.map((x) => <a style={{width}} href={x[1]}>{x[0]}</a>)}
      {symbols.map(symbol => <Price symbol={symbol}/>)}
      {computations.map(([title, name, displayFn]) => <Computation name={name} title={title} displayFn={displayFn}/>)}
      {fundingRates.map((symbol) => <FundingRate symbol={symbol} />)}
      {gateFundingRates.map((symbol) => <GateFundingRate symbol={symbol} />)}
      {taskProcessRunning.map((taskName) => <TaskProcessRunning taskName={taskName} />)}
    </div>
  );
};

export const Some = () => {
  const [state, setStateFn] = useState({});
  const setState = (updates) =>
    setStateFn((oldState) => ({ ...oldState, ...updates }));
  return <View {...state} />;
};

// db.createUser(
//   {
//     user: "dreday",
//     pwd:  "Fd9R1b7ZsIsFzSNpQ8J",   // or cleartext password
//     roles: [ { role: "readWrite", db: "money"  }]
//   }
// )
// mongorestore --uri mongodb+srv://127.0.0.1:27017/ 
// mongoexport --uri mongodb+srv://dreday:IZOdG7tuzvpqbyRL@cluster0.mlo8ado.mongodb.net/money --collection autoHedgeBuy --type json --out autoHedgeBuy
// mongoimport --uri mongodb://127.0.0.1:27017/money --collection autoHedgeBuy --type json --file autoHedgeBuy